@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* these colors are also duplicated in `tailwind.config.js` */
  --color-primary: #21C66A;
  --color-light-primary: #EBF8F1;
  --color-secondary: #28A9D1;
  --color-light-secondary: #DFF2F8;
  --color-white: #fff;
  --color-black: #000;
  --color-dark-grey: #555555;
  --color-light-grey: #f7f7f7;
  --color-grey: #DFDFDF;
  --color-typography-grey: #B0B0B0;
  --color-yellow: #FFD76A;
  --color-red: #FC7272;
  --font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  /* toastify */
  --toastify-color-success: var(--color-primary);
  --toastify-color-warning: var(--color-yellow);
  --toastify-color-error: var(--color-red);
  --toastify-color-info: var(--color-secondary);
  --toastify-font-family: var(--font-family);
}

@layer base {
  body, ::placeholder {
    margin: 0;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  h1, h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  small {
    font-size: 12px;
    font-weight: 400;
  }

  .e-learning-pages {
    a {
      @apply opacity-40;
    }

    a[href] {
      @apply text-primary opacity-100;
    }
  }
    
  *:focus-visible {
    @apply outline outline-[2px] outline-secondary;
  }
}

@layer utilities {
  .darker {
    box-shadow: inset 0 0 9999px rgba(0, 0, 0, 0.1);
    filter: contrast(1.1);
  }

  .darker-none {
    box-shadow: none;
    filter: none;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .absolute-centered {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}

.select__single-value {
  color: var(--color-black) !important;
}