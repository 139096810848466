.react-pdf__Document {
  width: 100%;
}

.react-pdf__Page {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
.react-pdf__Page__textContent {
  display: none;
}
::-webkit-scrollbar {
  width: 10px;

  @media screen and (max-width: 768px) {
    width: 5px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
