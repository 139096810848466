.h-screen-without-header {
  height: calc(100vh - 107px);
  top: 31px;
  position: absolute;
}

.h-screen-without-header-2 {
  height: calc(100vh - 107px);
  top: 0px;
  position: absolute;
}